export const FILTER_TYPE = Object.freeze({
  SEASON_ID: 'season_id',
  PRICE_ID: 'price_id',
  TAGS: 'tags',
  GROUP_ID: 'group_id',
  DIVISION_ID: 'division_id',
  PRODUCT_GROUP: 'Product Group',
  RENDER_ENGINE_FILTERS: {
    renderEngine: [
      {
        id: 'blender',
        name: 'Blender'
      },
      {
        id: 'vray',
        name: 'V-Ray'
      }
    ]
  }
})

export const EVENT_FILTER_TYPE = Object.freeze({
  NONE: 'none',
  OWNER: 'owner',
  CREATORS: 'creators',
  VIEWERS: 'viewers'
})

export const RENDER_ENGINE_TYPE = Object.freeze({
  vray: 'V-Ray',
  blender: 'Blender'
})
