<template>
  <div
    id="app"
    :class="{ 'dark-mode': isDarkMode }"
  >
    <MaintenanceBanner :src="MAINTENANCE_BANNER_SRC" />
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MaintenanceBanner } from '@pvhcorp/stitch-maintenance-banner'

export default {
  name: 'App',

  components: { MaintenanceBanner },

  data () {
    return {
      MAINTENANCE_BANNER_SRC: process.env.VUE_APP_MAINTENANCE_WINDOW_JSON || ''
    }
  },

  computed: {
    ...mapGetters(['isDarkMode']),

    /**
     * @returns {boolean}
     */
    isBrowserChrome () {
      return /Chrome|VStitcher/.test(navigator.userAgent)
    }
  },

  created () {
    this.setDarkMode(false)

    // Chrome notification
    if (!this.isBrowserChrome) {
      const chromeDownloadUrl = 'https://www.google.com/chrome/'

      this.$notify({
        title: 'Warning',
        dangerouslyUseHTMLString: true,
        message: `The DesignHUB works best in
          <br>
          <a href="${chromeDownloadUrl}">Google Chrome</a>.
          <br>
          <br>
          <small>We encourage you to
            <a href="${chromeDownloadUrl}">download Chrome</a>
            and continue there. If you wish to continue with your current browser,
            be aware that some things might not work as expected.</small>
          `,
        type: 'warning',
        duration: 10000
      })
    }
  },

  methods: {
    ...mapActions(['setDarkMode'])
  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
}

.dark-mode {
  background: #000;
}
</style>
