var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'stitch-loader',
    { 'stitch-loader--fill-container': _vm.fillContainer }
  ]},[_c('div',{class:[
      'stitch-loader__item',
      ( _obj = {}, _obj[("stitch-loader__item--" + _vm.size)] = _vm.size, _obj )
    ]})])}
var staticRenderFns = []

export { render, staticRenderFns }