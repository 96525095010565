<template>
  <div>
    <div
      v-if="group"
      class="header-access-manager"
    >
      <h2 class="header-access-manager__title">
        Manage People
      </h2>
      <div class="header-access-manager__group">
        <h3>
          Manage people that can access
          <strong>{{ group.name }}</strong>
        </h3>
      </div>
      <div class="header-access-manager__search">
        <ElementInput
          ref="searchInput"
          v-model="searchText"
          placeholder="Search..."
          size="small"
          clearable
          class="search__input"
          @input="updateSearchText"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          />
        </ElementInput>
        <ElementSelect
          v-if="roles.length > 0"
          v-model="selectedRoleId"
          placeholder="Everyone"
          size="small"
          class="search__role-selector"
          @change="updateSelectedRoleId"
        >
          <ElementOption
            v-for="roleFilter in roleFilters"
            :key="roleFilter.id"
            :label="roleFilter.display_name"
            :value="roleFilter.id"
          />
        </ElementSelect>
      </div>
      <div class="header-access-manager__add-new-invitation">
        <ElementButton
          v-if="canAddInvitation"
          type="text"
          class="add-new-invitation__button"
          @click="openNewInvitationForm"
        >
          Add Someone
        </ElementButton>
        <div class="add-new-invitation__spacer" />

        <StitchDialog
          title="Add Someone"
          :visible.sync="showNewInvitationForm"
          :show-close="false"
          append-to-body
          center
          :close-on-click-modal="true"
          :close-on-press-escape="true"
          class="add-new-invitation__dialog"
          @close="resetFormFields"
        >
          <ElementForm
            ref="newInvitationForm"
            label-position="top"
            class="add-form"
            :model="newInvitationForm"
            :rules="validationRules"
            hide-required-asterisk
          >
            <ElementFormItem
              label="Email Address"
              prop="inviteeList"
              class="add-form__item"
            >
              <div class="email-editor el-input__inner">
                <div class="email-editor__emails">
                  <ElementTag
                    v-for="(
                      invitee, inviteeIndex
                    ) in newInvitationForm.inviteeList"
                    :key="inviteeIndex"
                    closable
                    size="mini"
                    :type="invitee.isValid ? '' : 'danger'"
                    class="email-editor__email"
                    @close="removeInvitee(invitee)"
                  >
                    {{ invitee.email }}
                  </ElementTag>
                  <input
                    ref="editorEmailField"
                    v-model.trim="editorEmail"
                    class="email-editor__input"
                    @keydown.backspace.stop="removeLastInvitee"
                    @keydown.enter.prevent.stop="tokenizeNewEmail"
                    @keydown.space.prevent.stop="tokenizeNewEmail"
                    @keydown.tab.prevent.stop="tokenizeNewEmail"
                    @keydown.comma.prevent.stop="tokenizeNewEmail"
                    @paste.prevent.stop="handlePaste"
                  >
                </div>
                <ElementButton
                  v-if="editorEmail !== ''"
                  type="text"
                  class="email-editor__add el-input__icon"
                  @click="tokenizeNewEmail"
                >
                  <i class="el-icon-plus" />
                </ElementButton>
              </div>
            </ElementFormItem>

            <ElementFormItem
              label="Role"
              prop="roleId"
              class="add-form__item"
            >
              <ElementSelect
                v-model="newInvitationForm.roleId"
                @change="updateNewInvitationRoleId"
              >
                <ElementOption
                  v-for="role in roles"
                  :key="role.id"
                  :label="role.display_name"
                  :value="role.id"
                />
              </ElementSelect>
            </ElementFormItem>
          </ElementForm>
          <div slot="footer">
            <ElementButton
              plain
              class="add-form__cancel"
              @click="closeNewInvitationForm"
            >
              Cancel
            </ElementButton>
            <ElementButton
              type="primary"
              plain
              class="add-form__submit"
              @click="submitForm"
            >
              Done
            </ElementButton>
          </div>
        </StitchDialog>
      </div>
      <div class="header-access-manager__access-list">
        <div
          v-for="invitation in invitations"
          :key="invitation.item.id"
          class="user-access-entry"
        >
          <span class="user-access-entry__email">{{
            invitation.item.to_email
          }}</span>
          <div class="user-access-entry__actions">
            <ElementDropdown
              v-if="invitation.canEditInvitation"
              trigger="click"
              class="action__role-selector"
              @command="confirmUpdateInvitationRole"
            >
              <span
                class="action__role"
              >{{ invitation.item.role.display_name
              }}<i
                class="el-icon-arrow-down el-icon--right"
              /></span>
              <ElementDropdownMenu
                :append-to-body="false"
                class="action__role-selector-items"
              >
                <ElementDropdownItem
                  v-for="role in roles"
                  :key="role.id"
                  :command="{ invitation: invitation.item, role }"
                  class="action__role-selector-item"
                >
                  <div class="action__role-selector-item-name">
                    {{ getRoleType(role.name).displayName }}
                  </div>
                  <div class="action__role-selector-item-description">
                    {{ getRoleType(role.name).description }}
                  </div>
                </ElementDropdownItem>
              </ElementDropdownMenu>
            </ElementDropdown>
            <span
              v-else
              class="action__role action__role--single-option"
            >
              {{ invitation.item.role.display_name }}
            </span>
            <i
              :class="[
                'action__delete',
                'el-icon el-icon-close',
                {
                  'action__delete--hidden': !invitation.canRemoveInvitation
                }
              ]"
              @click="removeInvitation(invitation.item)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="header-access-manager header-access-manager--no-access"
    />
  </div>
</template>

<script>
import _debounce from 'lodash/debounce'
import { ROLE_TYPE } from '@/constants/roleType'
import VueTypes from 'vue-types'
import {
  InvitationShape,
  InviteeShape,
  UserShape,
  PermissionRoleShape,
  PermissionGroupShape
} from '@/types'

// https://emailregex.com/
/* eslint-disable no-useless-escape -- This regex expression inevitably breaks some eslint rules, so we need to disable it for this specific case. */
const REGEX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* eslint-enable no-useless-escape -- enabling rule */
export default {
  name: 'HeaderAccessManager',

  props: {
    user: UserShape,
    group: PermissionGroupShape,
    canAddInvitation: VueTypes.oneOfType([Array, Boolean]),
    newInviteeList: VueTypes.arrayOf(InviteeShape).def([]),
    invitations: VueTypes.arrayOf(InvitationShape).def([]),
    roles: VueTypes.arrayOf(PermissionRoleShape).def([])
  },

  data () {
    return {
      selectedRoleId: null,
      searchText: '',
      showNewInvitationForm: false,
      editorEmail: '',
      newInvitationForm: {
        inviteeList: [],
        roleId: null
      },
      validationRules: {
        inviteeList: [
          {
            validator: (rule, value, callback) => {
              if (value.length > 0) {
                callback()
              } else {
                callback(new Error('Please provide at least one email address'))
              }
            },
            trigger: ['blur', 'change']
          },
          {
            validator: this.validatorInviteeList
          }
        ],
        roleId: [
          {
            required: true,
            message: 'Please choose a role',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  computed: {
    /**
     * @returns {Array}
     */
    permissions () {
      return this.user.permissions ? this.user.permissions : []
    },

    /**
     * @returns {Array}
     */
    roleFilters () {
      return [
        {
          id: null,
          name: 'everyone',
          display_name: 'Everyone'
        },
        ...this.roles
      ]
    }
  },

  watch: {
    /**
     * @param {Array} value
     */
    newInviteeList (value) {
      this.newInvitationForm.inviteeList = [...value]

      if (value.length > 0) {
        this.openNewInvitationForm()
      } else {
        this.closeNewInvitationForm()
      }
    }
  },

  created () {
    this.newInvitationForm.inviteeList = [...this.newInviteeList]

    if (this.newInviteeList.length > 0) {
      this.openNewInvitationForm()
    }
  },

  methods: {
    /**
     * @param {number} roleId
     */
    updateSelectedRoleId (roleId) {
      this.selectedRoleId = roleId

      this.emitChangeFilters()
    },

    /**
     */
    updateSearchText: _debounce(
      /**
       */
      function () {
        this.emitChangeFilters()
      },
      250 // debounce time in ms
    ),

    /**
     */
    emitChangeFilters () {
      this.$emit('change-filters', {
        groupId: this.group ? this.group.id : null,
        roleId: this.selectedRoleId,
        searchText: this.searchText !== '' ? this.searchText : null
      })
    },

    /**
     * @param {number} roleId
     */
    updateNewInvitationRoleId (roleId) {
      this.newInvitationForm.roleId = roleId
    },

    /**
     */
    submitForm () {
      this.tokenizeNewEmail()

      this.$refs.newInvitationForm.validate(isValid => {
        if (!isValid) {
          return
        }

        const invitations = this.newInvitationForm.inviteeList.map(invitee => ({
          email: invitee.email,
          groupId: this.group.id,
          roleId: this.newInvitationForm.roleId
        }))

        this.$emit('add-invitations', invitations)
      })
    },

    /**
     * @param {object}              payload
     * @param {Invitation}          payload.invitation
     * @param {PermissionRoleShape} payload.role
     */
    confirmUpdateInvitationRole ({ invitation, role }) {
      if (role.id === invitation.role.id) {
        return
      }

      // show confirm modal if user is changing its own role from creator to viewer
      if (
        invitation.to_email === this.user.email &&
        role.name === ROLE_TYPE.VIEWER.name
      ) {
        this.$confirm(
          'Changing your role to Viewer means you’ll any longer have edit rights on anything in this team.',
          'Are you sure?',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
            showClose: false
          }
        )
          .then(() => {
            this.updateInvitationRole({
              invitation,
              role,
              refreshUser: true
            })
          })
          .catch(() => {})
      } else {
        this.updateInvitationRole({ invitation, role })
      }
    },

    /**
     * @param {object}              payload
     * @param {Invitation}          payload.invitation
     * @param {PermissionRoleShape} payload.role
     * @param {boolean}             payload.refreshUser
     */
    updateInvitationRole ({ invitation, role, refreshUser = false }) {
      this.$emit('update-invitation', {
        invitationId: invitation.id,
        roleId: role.id,
        refreshUser
      })
    },

    /**
     */
    validateInviteeListField () {
      this.$refs.newInvitationForm.validateField('inviteeList')
    },

    /**
     * @param {object}   rule
     * @param {object[]} value
     * @param {Function} callback
     */
    validatorInviteeList (rule, value, callback) {
      const uniqueInvitees = {}

      value.forEach(invitee => {
        const email = invitee.email.toLowerCase()

        if (new RegExp(REGEX_EMAIL).test(email)) {
          invitee.isValid = true
        } else {
          invitee.isValid = false
        }

        if (uniqueInvitees[email]) {
          invitee.isValid = false
        } else {
          uniqueInvitees[email] = true
        }
      })

      if (value.every(invitee => invitee.isValid)) {
        callback()
      } else {
        callback(
          new Error(
            'Please provide valid email addresses. No duplicates are allowed.'
          )
        )
      }
    },

    /**
     */
    tokenizeNewEmail () {
      const pendingInput = this.editorEmail

      if (pendingInput !== '') {
        this.newInvitationForm.inviteeList.push({
          email: pendingInput,
          isValid: true
        })
        this.editorEmail = ''
      }

      this.validateInviteeListField()
      this.focusEmailField()
    },

    /**
     * @param {ClipboardEvent} pasteEvent
     */
    handlePaste (pasteEvent) {
      const newInvitees = this.splitPasteContent(
        pasteEvent.clipboardData.getData('Text')
      ).map(email => ({ email, isValid: true }))

      this.newInvitationForm.inviteeList.push(...newInvitees)
      this.validateInviteeListField()
      this.focusEmailField()
    },

    /**
     * @param   {string}   pasteContent
     *
     * @returns {string[]}
     */
    splitPasteContent (pasteContent) {
      let parts

      if (!pasteContent) {
        return []
      }

      const pasteContentTrimmed = pasteContent.trim()
      const regexes = [
        /,\s*/g, // comma with optional spaces after
        /\s+/g // spaces
      ]

      for (const regex of regexes) {
        const trimParts = pasteContentTrimmed
          .split(regex)
          .filter(part => part.length > 0)
        parts = trimParts.map(email => email.trim())

        if (parts.length > 0 && parts[0] !== pasteContentTrimmed) {
          return parts
        }
      }

      return parts
    },

    /**
     * @param {Event} event
     */
    removeLastInvitee (event) {
      if (event.target.value === '') {
        event.preventDefault()

        const inviteeList = this.newInvitationForm.inviteeList

        if (inviteeList.length > 0) {
          this.removeInvitee(inviteeList[inviteeList.length - 1])
        }
      }
    },

    /**
     * @param {object} invitee
     */
    removeInvitee (invitee) {
      const index = this.newInvitationForm.inviteeList.findIndex(
        inviteeFromList => inviteeFromList.email === invitee.email
      )

      if (index > -1) {
        this.newInvitationForm.inviteeList.splice(index, 1)
      }

      this.validateInviteeListField()
      this.focusEmailField()
    },

    /**
     */
    focusEmailField () {
      this.$nextTick().then(() => {
        this.$refs.editorEmailField.focus()
      })
    },

    /**
     */
    resetEmailField () {
      this.$nextTick().then(() => {
        this.$refs.editorEmailField.form.reset()
      })
    },

    /**
     * @param {object} invitation
     */
    removeInvitation (invitation) {
      this.$confirm(
        `This will remove ${invitation.to_email} from ${this.group.name}. Are you sure?`,
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      ).then(() => {
        this.$emit('remove-invitation', { invitationId: invitation.id })
      })
    },

    /**
     */
    openNewInvitationForm () {
      this.showNewInvitationForm = true
      this.resetEmailField()
      this.focusEmailField()
    },

    /**
     */
    resetFormFields () {
      this.$refs.newInvitationForm.resetFields()
      this.showNewInvitationForm = false
    },

    /**
     */
    closeNewInvitationForm () {
      this.$refs.newInvitationForm.resetFields()
      this.showNewInvitationForm = false
    },

    /**
     * @param   {string} roleName
     *
     * @returns {object}
     */
    getRoleType (roleName) {
      return Object.values(ROLE_TYPE).find(role => role.name === roleName)
    }
  }
}
</script>

<style lang="scss" scoped>
$min-width-header-access-manager: spacing(50);
$max-width-email: 300px;
$max-height-editor: 300px;
$gap-emails: spacing(1/2);
$margin-right-role: 15px; // Not a standard size, but required in order to match Element's spacing system.
$top-email-tag-close: 1px;
$padding-email-add-button: 9px 6px;

.header-access-manager {
  display: flex;
  flex-direction: column;
  min-width: spacing(50);
  height: 100%;
  padding: 0 spacing(1);
  background-color: $white;
}

.header-access-manager__title {
  margin-bottom: spacing(2);
  text-align: center;
}

.header-access-manager__group {
  /deep/ .el-dropdown {
    font-size: inherit;
  }
}

.header-access-manager__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: spacing(1);
}

.search__role-selector {
  margin-left: spacing(1);
}

.add-new-invitation__dialog {
  display: flex;

  /deep/ .el-dialog {
    width: fit-content;
  }
}

.add-new-invitation__spacer {
  margin-bottom: spacing(1);
}

.add-form {
  width: spacing(50);
}

.add-form__item {
  /deep/ .el-form-item__label {
    padding: 0;
  }

  /deep/ .el-select {
    width: 100%;
  }
}

.email-editor {
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: spacing(5);
  max-height: $max-height-editor;
  padding: $gap-emails;
}

.email-editor__emails {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  overflow: auto;
  border: $gap-emails solid transparent;
}

.email-editor__email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin: 0 spacing(1) spacing(1) 0;

  /deep/ .el-tag__close {
    top: $top-email-tag-close;
  }
}

.email-editor__input {
  display: flex;
  flex-shrink: 1;
  width: 100%;
  padding: spacing(1/2) 0;
  font-size: inherit;
}

.email-editor__add {
  position: absolute;
  top: spacing(1);
  right: spacing(1/2);
  width: auto;
  height: fit-content;
  padding: $padding-email-add-button;
  background: $white;
}

.header-access-manager__access-list {
  @include scroll-y-smooth;

  max-height: 100%;
  border-top: $border-divider;
}

.action__delete {
  padding: spacing(1/2) spacing(2);
  color: $red;
  cursor: pointer;
  opacity: 0;

  &.action__delete--hidden {
    visibility: hidden;
  }
}

.user-access-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(1) 0;
  border-bottom: $border-divider;

  &:hover {
    .action__delete {
      opacity: 1;
    }
  }
}

.user-access-entry__email {
  @include text-ellipsis;

  max-width: $max-width-email;
  padding-left: spacing(2);
}

.action__role {
  cursor: pointer;
}

.action__role--single-option {
  margin-right: $margin-right-role;
  cursor: default;
}

.action__role-selector-items {
  padding: 0;
}

.action__role-selector-item-name {
  @include text-body-thick;

  margin-bottom: spacing(1);
  color: $grey-dark;
}

.action__role-selector-item-description {
  @include text-label;

  width: spacing(24);
  padding-bottom: spacing(1);
  word-break: break-word;
}

.action__role-selector-item {
  padding: spacing(1) spacing(2) 0;
  line-height: 1;

  &:not(:last-of-type) {
    .action__role-selector-item-description {
      border-bottom: $border-divider;
    }
  }
}
</style>
