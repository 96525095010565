import serverAPI from '@/services/serverAPI'

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 */
const fetchNotificationsSettings = async ({ commit }) => {
  commit('setNotificationsSettingsFailed', false)
  commit('setNotificationsSettingsLoading', true)

  try {
    const response = await serverAPI.fetchNotificationsSettings()

    commit('setNotificationsSettings', response)
  } catch (error) {
    commit('setNotificationsSettingsFailed', true)
  }

  commit('setNotificationsSettingsLoading', false)
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {string}       payload.type
 * @param {string}       payload.platform
 * @param {string}       payload.value
 */
const updateNotificationsSettings = async ({ commit }, payload) => {
  commit('setNotificationsSettingsFailed', false)
  commit('setNotificationsSettingsUpdating', true)

  try {
    const response = await serverAPI.updateNotificationsSettings(payload)

    commit('setNotificationsSettings', response)
  } catch (error) {
    commit('setNotificationsSettingsFailed', true)
  }

  commit('setNotificationsSettingsUpdating', false)
}

export default {
  fetchNotificationsSettings,
  updateNotificationsSettings
}
