// Types and Platforms are in correspondance with the ones in the database.

export const NOTIFICATIONS_SETTINGS_TYPE = Object.freeze({
  FABRICS: 'fabric_notification_preferences'
})

export const NOTIFICATIONS_SETTINGS_PLATFORM = Object.freeze({
  EMAIL: 'email_notifications',
  WEB: 'web_notifications'
})
