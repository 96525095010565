import Vue from 'vue'
import Vuex from 'vuex'

import rootStore from '@/store/modules/root/index'
import itemStore from '@/store/modules/item/index'
import jobStore from '@/store/modules/job/index'
import invitationStore from '@/store/modules/invitation/index'
import notificationsStore from '@/store/modules/notifications/index'

Vue.use(Vuex)

export default new Vuex.Store({
  // Do not enable strict mode for production. https://vuex.vuejs.org/guide/strict.html#development-vs-production
  strict: process.env.NODE_ENV === 'development',
  modules: {
    rootStore,
    itemStore,
    jobStore,
    invitationStore,
    notificationsStore
  }
})
