import _cloneDeep from 'lodash/cloneDeep'

/**
 * @param {object} state
 * @param {object} payload
 */
const setNotificationsSettings = (state, payload) => {
  state.data = _cloneDeep(payload)
}

/**
 * @param {object}  state
 * @param {boolean} isLoading
 */
const setNotificationsSettingsLoading = (state, isLoading) => {
  state.isLoading = isLoading
}

/**
 * @param {object}  state
 * @param {boolean} isUpdating
 */
const setNotificationsSettingsUpdating = (state, isUpdating) => {
  state.isUpdating = isUpdating
}

/**
 * @param {object}  state
 * @param {boolean} hasFailed
 */
const setNotificationsSettingsFailed = (state, hasFailed) => {
  state.hasFailed = hasFailed
}

export default {
  setNotificationsSettings,
  setNotificationsSettingsLoading,
  setNotificationsSettingsUpdating,
  setNotificationsSettingsFailed
}
