import { mapGetters } from 'vuex'
import { LIBRARY_TYPE } from '@/constants/libraryType'

export const BrowzwearPlugin = {
  computed: {
    ...mapGetters({
      browzwearAPI: 'getBrowzwearAPI',
      pluginUser: 'getCognitoUserData'
    })
  },

  watch: {
    /**
     * @param {boolean} newUser
     */
    pluginUser (newUser) {
      if (newUser) {
        this.sendUserInfoToPlugin()
      }
    }
  },

  methods: {
    /**
     * Sends user data to browzwearAPI. Expects to be called only when there is a valid user
     * To test this on localhost:8080 you MUST comment the development environment exception on  main.js redirectToLogin() function
     * and also change COGNITO variables to actually make the login with a valid email.
     */
    sendUserInfoToPlugin () {
      if (!this.browzwearAPI) {
        return
      }

      const message = {
        action: 'login',
        data: this.pluginUser,
        cookie: document.cookie,
        version: 1
      }
      this.browzwearAPI.sendMessage(message)
    },

    /**
     * Sends 3D information to BW API. Blocks require a special action and file
     *
     * @param   {object} item
     * @param   {number} index
     *
     * @returns {object}
     */
    sendDataToBrowzwear (item, index = 0) {
      let actionType = 'download_asset'
      let fileType = 'u3ma'

      if (item.libraryType === LIBRARY_TYPE.BLOCK) {
        actionType = 'open_garment'
        fileType = 'model3D'
      }

      const fileIndex = index
      const file3D = item.filesDict[fileType][fileIndex] || null

      if (!file3D) {
        return
      }

      const message = {
        action: actionType,
        data: {
          asset_type: item.libraryType,
          asset_id: item.id,
          asset_U3MA_url: file3D.assets_url
        },
        cookie: document.cookie,
        version: 1
      }

      return this.browzwearAPI.sendMessage(message)
    },

    /**
     * Sends the new endpoint URL to the plugin
     *
     * @param   {string} newUrl
     *
     * @returns {object}
     */
    sendURLChangeToPlugin (newUrl) {
      if (!this.browzwearAPI) {
        return
      }

      const message = {
        action: 'update_fe_url',
        data: {
          url: newUrl
        },
        version: 1
      }

      return this.browzwearAPI.sendMessage(message)
    }
  }
}
