import Vue from 'vue'
import store from '@/store/store'
import { FEATURE } from '@/constants/feature'

/**
 * @returns {boolean}
 */
const canUserAccessStitchPlugin = () => {
  const user = store.getters.getCognitoUserData

  return Vue.prototype.$featureFlagsManager.canAccessFeature(
    FEATURE.STITCH_PLUGIN,
    {
      user
    }
  )
}

/**
 * @returns {boolean}
 */
const canTeamAccessRenderEngineChoice = () => {
  const currentTeamId =
    store.getters.getRoute.query.group_id ??
    store.getters.getRoute.query.division_id

  return !Vue.prototype.$featureFlagsManager.isTeamBlacklisted(
    FEATURE.RENDER_ENGINE_CHOICE,
    {
      currentTeamId
    }
  )
}

/**
 * @returns {boolean}
 */
const isVRayOnlyTeam = () => {
  const currentTeamId =
    store.getters.getRoute.query.group_id ??
    store.getters.getRoute.query.division_id

  return Vue.prototype.$featureFlagsManager.canTeamAccessFeature(
    FEATURE.VRAY_ONLY_TEAM,
    {
      currentTeamId
    }
  )
}

/**
 * @returns {boolean}
 */
const canUserAccessTrimTabPlugin = () => {
  const user = store.getters.getCognitoUserData

  return Vue.prototype.$featureFlagsManager.canAccessFeature(
    FEATURE.TRIM_TAB_PLUGIN,
    {
      user
    }
  )
}

/**
 * @returns {boolean}
 */
const canUserAccessEndpointManager = () => {
  const user = store.getters.getCognitoUserData

  return Vue.prototype.$featureFlagsManager.canAccessFeature(
    FEATURE.ENDPOINT_MANAGER_PLUGIN,
    {
      user
    }
  )
}

/**
 * @returns {boolean}
 */
const canUserAccessStyleComments = () => {
  const user = store.getters.getCognitoUserData

  return Vue.prototype.$featureFlagsManager.canAccessFeature(
    FEATURE.STYLE_COMMENTS,
    {
      user
    }
  )
}

/**
 * @returns {boolean}
 */
const canUserAccessNotificationsSettings = () => {
  const user = store.getters.getCognitoUserData

  return Vue.prototype.$featureFlagsManager.canAccessFeature(
    FEATURE.NOTIFICATIONS_SETTINGS,
    {
      user
    }
  )
}

/**
 * @returns {boolean}
 */
const canUserAccessWorkspaces = () => {
  const user = store.getters.getCognitoUserData

  return Vue.prototype.$featureFlagsManager.canAccessFeature(
    FEATURE.WORKSPACES,
    {
      user
    }
  )
}

const FeatureFlags = {
  canUserAccessStitchPlugin,
  canTeamAccessRenderEngineChoice,
  canUserAccessTrimTabPlugin,
  canUserAccessEndpointManager,
  canUserAccessStyleComments,
  canUserAccessNotificationsSettings,
  canUserAccessWorkspaces,
  isVRayOnlyTeam
}

export default FeatureFlags
