import { NOTIFICATIONS_SETTINGS_TYPE } from '@/constants/notifications'

/**
 * @param   {object} notificationsSettings
 * @param   {string} notificationsSettingsKey
 *
 * @returns {object}
 */
const constructNotificationsSettings = (
  notificationsSettings,
  notificationsSettingsKey
) => {
  notificationsSettings[notificationsSettingsKey] = null

  return notificationsSettings
}

export default () => ({
  data: Object.values(NOTIFICATIONS_SETTINGS_TYPE).reduce(
    constructNotificationsSettings,
    {}
  ),
  isLoading: false,
  isUpdating: false,
  hasFailed: false
})
