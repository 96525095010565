var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isCreator || _vm.item.type === _vm.STYLE_EVENT_TYPE.COMMENT)?_c('div',{class:[
    'style-comments-event',
    { 'style-comments-event--faded': _vm.isFaded }
  ]},[(_vm.item.type === _vm.STYLE_EVENT_TYPE.COMMENT)?_c('div',{class:[
      'style-comments-event__content',
      'style-comments-event__content--comment',
      _vm.item.comment.role === _vm.ROLE_TYPE.CREATOR.displayName
        ? 'style-comments-event__content--comment-creator'
        : 'style-comments-event__content--comment-viewer'
    ]},[_c('section',{staticClass:"style-comments-event__content-section"},[_c('div',{staticClass:"style-comments-event__text"},[_vm._v(" "+_vm._s(_vm.item.comment.text)+" ")]),_c('div',{staticClass:"style-comments-event__metadata"},[_c('strong',[_vm._v(_vm._s(_vm.item.comment.contributor.email)+" ("+_vm._s(_vm.item.comment.role)+")")]),_vm._v(" "+_vm._s(_vm.formatDateShort(_vm.item.updated_at))+" ")])]),(_vm.showControls && _vm.isCreator)?_c('StitchDropdown',{ref:"optionMenuDropdown",attrs:{"icon-type":_vm.ICON_TYPE.MENU,"append-to-body":false,"dropdown-items":_vm.dropdownItems}}):_vm._e()],1):(_vm.item.type === _vm.STYLE_EVENT_TYPE.MAIN_VERSION_SET)?_c('div',{staticClass:"style-comments-event__content style-comments-event__content--info"},[_c('b',[_vm._v(_vm._s(_vm.getVersionName(_vm.item.style_version_id)))]),_vm._v(" is now the Main Version ")]):(_vm.item.type === _vm.STYLE_EVENT_TYPE.NEW_VERSION_ADDED)?_c('div',{staticClass:"style-comments-event__content style-comments-event__content--info"},[_c('b',[_vm._v("New Version")]),_vm._v(" posted 😎 ")]):_c('div',{staticClass:"style-comments-event__content style-comments-event__content--info"},[_vm._v(" Unsupported event type "+_vm._s(_vm.item.type)+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }