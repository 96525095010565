<template>
  <StitchDialog
    title="Notifications"
    class="header-notifications-settings"
    :visible.sync="showNotificationsSettings"
    center
    append-to-body
  >
    <StitchLoader
      v-if="isNotificationsSettingsLoading"
      data-testid="header-notifications-settings-loader"
    />
    <p
      v-else-if="hasNotificationsSettingsFailed"
      data-testid="header-notifications-settings-failed"
      class="header-notifications-settings__failed-message"
    >
      Failed to load notifications settings.<br>
      Please try again later.
    </p>
    <div
      v-else
      data-testid="header-notifications-settings-content"
      class="header-notifications-settings__body"
    >
      <StitchIcon
        class="header-notifications-settings__icon"
        name="email-notifications"
      />
      <p class="header-notifications-settings__text">
        Receive an email when a fabric is added to the library
      </p>
      <ElementSwitch
        data-testid="header-notifications-settings-switch"
        :value="notificationsSettingsFabricEmail"
        :disabled="isNotificationsSettingsUpdating"
        @change="toggleNotificationsSettings"
      />
    </div>
  </StitchDialog>
</template>

<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'
import {
  NOTIFICATIONS_SETTINGS_TYPE,
  NOTIFICATIONS_SETTINGS_PLATFORM
} from '@/constants/notifications'

export default {
  name: 'HeaderNotificationsSettings',

  props: {
    visible: VueTypes.bool.isRequired
  },

  data: () => ({
    showNotificationsSettings: false
  }),

  computed: {
    ...mapGetters(['getNotificationsSettingsByType']),

    ...mapGetters({
      isNotificationsSettingsLoading: 'getIsNotificationsSettingsLoading',
      isNotificationsSettingsUpdating: 'getIsNotificationsSettingsUpdating',
      hasNotificationsSettingsFailed: 'getHasNotificationsSettingsFailed'
    }),

    /**
     * @returns {object | null}
     */
    notificationsSettingsFabric () {
      return this.getNotificationsSettingsByType(
        NOTIFICATIONS_SETTINGS_TYPE.FABRICS
      )
    },

    /**
     * @returns {boolean | null}
     */
    notificationsSettingsFabricEmail () {
      return this.notificationsSettingsFabric
        ? this.notificationsSettingsFabric[
          NOTIFICATIONS_SETTINGS_PLATFORM.EMAIL
        ]
        : null
    }
  },

  watch: {
    /**
     */
    visible () {
      if (this.showNotificationsSettings !== this.visible) {
        this.showNotificationsSettings = this.visible
      }
    },

    /**
     */
    showNotificationsSettings () {
      if (this.showNotificationsSettings !== this.visible) {
        this.$emit('update:visible', this.showNotificationsSettings)
      }

      this.getNotificationsSettingsIfNeeded()
    }
  },

  methods: {
    ...mapActions([
      'fetchNotificationsSettings',
      'updateNotificationsSettings'
    ]),

    /**
     */
    getNotificationsSettingsIfNeeded () {
      if (
        this.showNotificationsSettings &&
        this.notificationsSettingsFabric === null
      ) {
        this.fetchNotificationsSettings()
      }
    },

    /**
     */
    toggleNotificationsSettings () {
      if (!this.isNotificationsSettingsUpdating) {
        this.updateNotificationsSettings({
          type: NOTIFICATIONS_SETTINGS_TYPE.FABRICS,
          platform: NOTIFICATIONS_SETTINGS_PLATFORM.EMAIL,
          value: !this.notificationsSettingsFabricEmail
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$header-notifications-settings-width: 386px;

.header-notifications-settings {
  /deep/ .el-dialog {
    width: $header-notifications-settings-width;
  }

  /deep/ .el-dialog__title {
    font-weight: $font-weight-semibold;
  }
}

.header-notifications-settings__body {
  display: flex;
  align-items: center;
}

.header-notifications-settings__text {
  margin: 0 spacing(2.5);
  word-break: break-word;
}

.header-notifications-settings__icon {
  width: spacing(3.5);
}

.header-notifications-settings__failed-message {
  display: inline-block;
  width: 100%;
  text-align: center;
}
</style>
