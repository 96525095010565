<template>
  <StitchDialog
    :class="[
      'form-generator',
      `form-generator--step${activeStep}`,
      {
        'form-generator--fullscreen': modalOptions && modalOptions.fullscreen,
        'form-generator--with-steps': showStepBar
      }
    ]"
    :visible.sync="showDialog"
    v-bind="modalOptions"
    append-to-body
    @closed="onClose"
  >
    <div
      slot="title"
      class="form-generator__title-area"
    >
      <div class="form-generator__title-line">
        <template v-if="currentStepMetaData.headerLink">
          <ElementButton
            type="text"
            class="form-generator__title-header-link"
            @click="handleHeaderLinkClick"
          >
            {{ currentStepMetaData.headerLink.text }}
          </ElementButton>
        </template>

        <div class="form-generator__title">
          {{ currentStepMetaData.title }}
        </div>
      </div>
      <div
        v-if="currentStepMetaData.notes"
        class="form-generator__notes"
      >
        <InputNotes :notes="currentStepMetaData.notes" />
      </div>
    </div>

    <!-- ------------------------------ -->
    <!-- ----------Form---------------- -->
    <!-- ------------------------------ -->

    <ElementForm
      v-if="formData[currentStepIndex]"
      ref="formGenerator"
      :model="formData[currentStepIndex]"
      :class="[
        'form-generator__content',
        {
          'form-generator__content--split': shouldBeSplit,
          'form-generator__content--empty': currentStepFields.length === 0
        }
      ]"
      label-position="top"
    >
      <template v-for="(field, key) in currentStepFields">
        <!-- :is => Pick the component matching the return function -->
        <component
          :is="TYPE_MAP[field.type]"
          v-if="isVisible(field)"
          :key="`${currentStepIndex}-${key}`"
          :input-key="field.id"
          :parameters="field"
          :is-disabled="isDisabledMap[field.id]"
          :default-value="
            getDefaultValue(field.id, field.defaultValue, field.forcedValue)
          "
          :custom-error="getCustomError(field.id)"
          :options="currentOptions(field)"
          @set-data="setData"
          @on-close="onClose"
        />
      </template>
    </ElementForm>

    <!-- ------------------------------ -->
    <!-- --------Footer---------------- -->
    <!-- ------------------------------ -->
    <div
      slot="footer"
      class="form-generator__confirm-button"
    >
      <ElementButton
        type="primary"
        plain
        :loading="isValidating"
        :disabled="!areRequiredFieldsFilled"
        @click="validateCurrentStep"
      >
        {{ currentStepMetaData.submitText }}
      </ElementButton>

      <ElementLink
        v-if="currentStepMetaData.skip"
        class="skip-button"
        type="primary"
        :underline="false"
        @click="onSkip"
      >
        {{ currentStepMetaData.skip.name }}
      </ElementLink>

      <div
        v-if="showStepBar"
        data-testid="stepBar"
        class="form-generator__step-bar"
      >
        Step {{ activeStep }}/{{ totalSteps }}
      </div>
    </div>
  </StitchDialog>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'
import InputFile from './inputComponents/InputFile'
import InputText from './inputComponents/InputText'
import InputSelect from './inputComponents/InputSelect'
import InputTags from './inputComponents/InputTags'
import InputCheckbox from './inputComponents/InputCheckbox'
import InputSwitch from './inputComponents/InputSwitch'
import InputPreset from './inputComponents/InputPreset'
import InputNotes from './inputComponents/InputNotes'

import FormServices from '../services/FormServices'

const TYPE_MAP = {
  file: InputFile.name,
  text: InputText.name,
  select: InputSelect.name,
  tags: InputTags.name,
  checkbox: InputCheckbox.name,
  switch: InputSwitch.name,
  preset: InputPreset.name
}

export default {
  name: 'FormGenerator',

  components: {
    InputFile,
    InputText,
    InputSelect,
    InputTags,
    InputCheckbox,
    InputSwitch,
    InputPreset,
    InputNotes
  },

  props: {
    showFormGenerator: {
      type: Boolean,
      required: false,
      default: false
    },
    formConfiguration: {
      validator: prop => typeof prop === 'object' || prop === null,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => ({})
    },
    originalData: {
      type: Object,
      required: false,
      default: null
    },
    defaultData: {
      type: Object,
      required: false,
      default: null
    },
    specificStepIdToEdit: {
      type: String,
      required: false,
      default: null
    },
    isCreation: {
      type: Boolean,
      required: false,
      default: false
    },
    additionalData: {
      type: Object,
      required: false,
      default: null
    },
    modalOptions: {
      type: Object,
      required: false,
      default: null
    },
    showStepBar: {
      type: Boolean,
      required: false,
      default: false
    },
    injectedMethods: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      showDialog: false,
      currentStepIndex: 0,
      formData: [],
      error: null,
      updatedData: null,
      originalFormData: [],
      currentStepFields: [],
      TYPE_MAP,
      isValidating: false
    }
  },

  computed: {
    /**
     * @returns {object[]}
     */
    formConfigurationSteps () {
      return this.formConfiguration ? this.formConfiguration.steps : []
    },

    /**
     * @returns {object | null}
     */
    currentFormData () {
      if (this.updatedData) {
        return {
          ...FormServices.copyFileDictToMainObject(this.originalData),
          ...FormServices.copyFileDictToMainObject(this.updatedData)
        }
      }

      return FormServices.copyFileDictToMainObject(this.originalData)
    },

    /**
     * @returns {boolean}
     */
    editing () {
      return this.currentFormData !== null
    },

    /**
     * @returns {object[]}
     */
    validSteps () {
      const steps = this.formConfigurationSteps

      if (this.isCreation === true) {
        return steps
      }

      if (this.specificStepIdToEdit === null) {
        return steps.filter(step => step.edit !== false)
      } else {
        return steps.filter(step => step.id === this.specificStepIdToEdit)
      }
    },

    /**
     * @returns {object}
     */
    currentStep () {
      return this.validSteps[this.currentStepIndex] || null
    },

    /**
     * @returns {string}
     */
    currentStepPartialMethod () {
      if (this.editing && this.currentStep.submit.processUpdate) {
        return this.currentStep.submit.processUpdate
      }

      return this.currentStep.submit.process || null
    },

    /**
     * @returns {object}
     */
    currentStepMetaData () {
      if (this.currentStep === null) {
        return {}
      }

      const currentStepSubmit = this.currentStep.submit

      return {
        title: this.currentStep.title,
        headerLink: this.currentStep.headerLink,
        notes: this.currentStep.notes || null,
        autoSubmitIfSingle: this.currentStep.autoSubmitIfSingle,
        autoSubmitIfPrefilled:
          this.currentStep.autoSubmitIfPrefilled &&
          this.specificStepIdToEdit === null,
        async: this.currentStep.async || false,
        submitPartial: !!this.currentStepPartialMethod,
        submitPartialMethod: this.currentStepPartialMethod,
        submitText: currentStepSubmit.name,
        forceFormCompletion: currentStepSubmit.finish,
        skip: this.currentStep.skip, // skips current step without filling data
        stepIdToGo: currentStepSubmit?.stepIdToGo, // goes to another step, adding data from current step
        enabledIfFieldsFilled: currentStepSubmit?.enabledIfFieldsFilled,
        clearFieldsOnStepEnd: currentStepSubmit?.clearFieldsOnStepEnd || []
      }
    },

    /**
     * @returns {object}
     */
    isDisabledMap () {
      return this.currentStepFields.reduce((map, field) => {
        let isDisabled

        if (field.disabledIfFilled) {
          isDisabled = field.disabledIfFilled.every(fieldId => {
            return this.isFieldFilled(fieldId)
          })
        } else {
          isDisabled = false
        }

        map[field.id] = isDisabled

        return map
      }, {})
    },

    /**
     * @returns {boolean}
     */
    shouldBeSplit () {
      return this.currentStepFields.length > 5
    },

    /**
     * @returns {boolean}
     */
    areRequiredFieldsFilled () {
      if (this.currentStepMetaData.enabledIfFieldsFilled) {
        return this.currentStepMetaData.enabledIfFieldsFilled.every(fieldId =>
          this.isFieldFilled(fieldId)
        )
      }

      return true
    },

    /**
     * @returns {object}
     */
    processedItemData () {
      const itemData = {
        new: {
          ...this.additionalData,
          ...this.processFormData(this.formData)
        },
        current: this.processFormData(this.originalFormData) || {}
      }

      if (this.editing) {
        itemData.itemId =
          (this.updatedData && this.updatedData.id) || this.originalData.id
      }

      return itemData
    },

    /**
     * @returns {number}
     */
    activeStep () {
      return this.currentStepIndex + 1
    },

    /**
     * @returns {number}
     */
    totalSteps () {
      return this.validSteps.length
    }
  },

  watch: {
    /**
     */
    showFormGenerator () {
      this.showDialog = this.showFormGenerator

      if (this.showFormGenerator) {
        this.initializeCurrentStep()
      }
    }
  },

  methods: {
    // ///////////////////////
    // INITIALIZATION
    // ///////////////////////

    /**
     */
    async initializeCurrentStep () {
      this.isValidating = false
      this.formData[this.currentStepIndex] =
        this.formData[this.currentStepIndex] || {}
      this.setCurrentStepFields()
      this.initializeOriginalFormDataStep()

      // Need to wait for areRequiredFieldsFilled to compute.
      await this.$nextTick()
      this.checkAutoSubmit()
    },

    /**
     */
    setCurrentStepFields () {
      this.currentStepFields = FormServices.parseFieldsForRelatedData(
        this.currentStep.fields,
        this.formData,
        this.isCreation
      )
    },

    /**
     */
    initializeOriginalFormDataStep () {
      this.originalFormData[this.currentStepIndex] = {}

      if (!this.editing) {
        return
      }

      this.currentStepFields.forEach(field => {
        // To handle the ID 0 on Sustainability
        const originalFieldData =
          this.currentFormData[field.id] !== undefined
            ? this.currentFormData[field.id]
            : null
        const originalStepData = this.originalFormData[this.currentStepIndex]
        originalStepData[field.id] = originalFieldData
      })
    },

    /**
     * @param   {string}  fieldId
     *
     * @returns {boolean}
     */
    isFieldFilled (fieldId) {
      const fieldValue =
        this.formData[this.currentStepIndex] &&
        this.formData[this.currentStepIndex][fieldId]

      return (
        typeof fieldValue === 'boolean' ||
        typeof fieldValue === 'number' ||
        // Checks for empty string, empty array, empty object; doesn't work for numbers/booleans; returns false for null/undefined
        _isEmpty(fieldValue) === false
      )
    },

    /**
     */
    checkAutoSubmit () {
      // Set data and Submit if autoSubmit
      if (
        this.currentStepMetaData.autoSubmitIfSingle &&
        this.currentStepFields.length === 1
      ) {
        this.isValidating = true
        const field = this.currentStepFields[0]
        const fieldDefaultValue = FormServices.getDefaultValue({
          currentStepIndex: this.currentStepIndex,
          formData: this.formData,
          originalFormData: this.originalFormData,
          defaultData: this.defaultData,
          currentFieldId: field.id,
          defaultValue: field.defaultValue,
          forcedValue: field.forcedValue
        })

        this.setData({
          key: field.id,
          value: fieldDefaultValue,
          relatedField: field.relatedField
        })
        this.validateCurrentStep()
      } else if (
        this.currentStepMetaData.autoSubmitIfPrefilled &&
        this.areRequiredFieldsFilled
      ) {
        // Move to the next step if correct data is prefilled.
        this.validateCurrentStep()
      }
    },

    // ///////////////////////////
    // SET DATA FROM INPUT EVENT
    // ///////////////////////////

    /**
     * @param {object} dataSet
     */
    setData (dataSet) {
      if (dataSet.relatedField) {
        const relatedFieldId = dataSet.relatedField.id
        const relatedFieldIndex = dataSet.relatedField.index
        const relatedFieldProperty = dataSet.relatedField.propertyAffected
        const stepData = this.formData.find(stepData => {
          return stepData[relatedFieldId]
        })
        stepData[relatedFieldId][relatedFieldIndex][relatedFieldProperty] =
          dataSet.value
      }

      this.formData.splice(this.currentStepIndex, 1, {
        ...this.formData[this.currentStepIndex],
        [dataSet.key]: dataSet.value
      })
      this.executeTriggers(dataSet)
    },

    /**
     * @param {object} dataSet
     */
    executeTriggers (dataSet) {
      if (dataSet.trigger) {
        this.$emit('execute-trigger', dataSet.trigger, {
          dataSet,
          processedItemData: this.processedItemData
        })
      }
    },

    // ///////////////
    // GET
    // ///////////////
    /**
     * @param   {string} currentFieldId
     * @param   {*}      defaultValue
     * @param   {*}      forcedValue
     *
     * @returns {*}
     */
    getDefaultValue (currentFieldId, defaultValue, forcedValue) {
      return FormServices.getDefaultValue({
        currentStepIndex: this.currentStepIndex,
        formData: this.formData,
        originalFormData: this.originalFormData,
        defaultData: this.defaultData,
        currentFieldId,
        defaultValue,
        forcedValue
      })
    },

    /**
     * @param   {string}        fieldId
     *
     * @returns {object | null}
     */
    getCustomError (fieldId) {
      if (!this.error) {
        return null
      }

      return this.error[fieldId] ? this.error[fieldId] : null
    },

    // /////////////
    // VALIDATION
    // /////////////

    /**
     */
    async validateCurrentStep () {
      this.isValidating = true
      this.error = null

      await this.$refs.formGenerator.validate(async valid => {
        const validateArguments = {
          formConfiguration: this.formConfiguration,
          currentStepMetaData: this.currentStepMetaData,
          processedItemData: this.processedItemData,
          store: this.$store,
          valid,
          asyncSuccessCallbacks: [this.initializeOriginalFormDataStep]
        }

        if (valid) {
          const { updatedData, error } = await FormServices.validateStep(
            validateArguments
          )

          this.error = error || null

          if (updatedData) {
            this.updatedData = this.afterUpdateCallback(updatedData)
          }

          this.initializeOriginalFormDataStep()

          if (!error) {
            this.completeCurrentStep()
          }
        }

        this.$emit('validated', valid)
        this.isValidating = false
      })
    },

    /**
     */
    onSkip () {
      const skipStep = true
      this.completeCurrentStep(skipStep)
    },

    /**
     * @param   {string} stepIdToGo
     *
     * @returns {number}
     */
    getStepIndexById (stepIdToGo) {
      return this.validSteps.findIndex(step => step.id === stepIdToGo)
    },

    /**
     * @param {boolean} skipStep
     */
    completeCurrentStep (skipStep = false) {
      let stepIdToGo = this.currentStepMetaData.stepIdToGo || null
      let stepIdToGoIndex =
        stepIdToGo !== null ? this.getStepIndexById(stepIdToGo) : null

      if (skipStep) {
        stepIdToGo = this.currentStepMetaData.skip.stepIdToGo
        stepIdToGoIndex = this.getStepIndexById(stepIdToGo)
      }

      const hasReachedEnd = FormServices.hasReachedFormEnd({
        forceFormCompletion: this.currentStepMetaData.forceFormCompletion,
        stepIdToGo,
        stepIdToGoIndex,
        currentStepIndex: this.currentStepIndex,
        validSteps: this.validSteps
      })

      if (hasReachedEnd === false) {
        if (skipStep === true) {
          this.formData[this.currentStepIndex] = {}
        }

        if (stepIdToGo === null) {
          this.currentStepIndex++
        } else {
          this.currentStepIndex = stepIdToGoIndex
        }

        this.initializeCurrentStep()

        // Scrolls back to the top after step complete
        // The scroll is on the dialog body...
        if (
          this.$refs.formGenerator &&
          this.$refs.formGenerator.$el &&
          this.$refs.formGenerator.$el.parentElement
        ) {
          this.$refs.formGenerator.$el.parentElement.scrollTop = 0
        }
      } else {
        this.$emit('form-complete', true)
      }

      this.isValidating = false
    },

    // //////////
    // PROCESS
    // //////////
    /**
     * @param   {object} formData
     *
     * @returns {object}
     */
    processFormData (formData) {
      const exportedData = {}
      formData.forEach(step => {
        Object.entries(step).forEach(([key, fieldValue]) => {
          // Bypassing the temporary properties (for related data)
          if (key.startsWith('temporary')) {
            return
          }

          let newFieldValue = fieldValue

          if (fieldValue && fieldValue.type === 'file') {
            newFieldValue = this.processFiles(fieldValue)
          }

          exportedData[key] = newFieldValue
        })
      })

      return exportedData
    },

    /**
     * @param   {object[]} files
     *
     * @returns {object[]}
     */
    processFiles (files) {
      return files.map(file => {
        return {
          name: file.name,
          description: file.description || file.name,
          file: file.raw,
          id: file.id || undefined,
          extension: file.extension
        }
      })
    },

    // //////////////
    // HELPERS
    // //////////////

    /**
     */
    onClose () {
      this.currentStepIndex = 0
      this.formData = []
      this.error = null
      this.updatedData = null
      this.originalFormData = []
      this.currentStepFields = []

      this.$emit('form-close')
    },

    /**
     * @param   {object}   field
     *
     * @returns {object[]}
     */
    currentOptions (field) {
      return typeof field.injectOptions === 'string'
        ? this.options[field.injectOptions] || []
        : field.injectOptions
    },

    /**
     * @param   {object}  field
     *
     * @returns {boolean}
     */
    isVisible (field) {
      return field.visible ?? true
    },

    /**
     * @param   {object} updatedData
     *
     * @returns {object}
     */
    afterUpdateCallback (updatedData) {
      if (this.editing === false) {
        this.currentStepMetaData.clearFieldsOnStepEnd.forEach(fieldToDelete => {
          if (updatedData[fieldToDelete]) {
            delete updatedData[fieldToDelete]
          }

          if (updatedData.filesDict && updatedData.filesDict[fieldToDelete]) {
            delete updatedData.filesDict[fieldToDelete]
          }
        })
      }

      return updatedData
    },

    /**
     */
    handleHeaderLinkClick () {
      const methodName = this.currentStepMetaData.headerLink.methodName

      if (
        this.injectedMethods &&
        typeof this.injectedMethods[methodName] === 'function'
      ) {
        this.injectedMethods[methodName]()
      } else if (typeof this[methodName] === 'function') {
        this[methodName]()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/utils';

$form-content-split-width: $input-width * 2 + spacing(6);
$dialog-padding: 20px;
$title-link-padding: 2px 0 0;
$title-link-arrow-size: spacing(2);

.form-generator {
  /deep/ .el-dialog__body {
    padding-top: 0;
    padding-bottom: spacing(1);
  }

  &.form-generator--fullscreen {
    padding: 0;

    /deep/ .el-dialog {
      border-radius: 0;
    }

    /deep/ .el-dialog__body {
      justify-content: center;
    }
  }

  &.form-generator--with-steps {
    /deep/ .el-dialog {
      overflow: visible;
    }
  }
}

.form-generator__title-area {
  @include flex-center;

  flex-direction: column;
  color: $grey-dark;
  font-weight: $font-weight-semibold;
  font-size: $font-size-xl;
  font-family: $primary-font;
}

.form-generator__title-line {
  @include flex-center;

  flex-flow: row nowrap;
  width: 100%;
}

.form-generator__title-header-link {
  position: absolute;
  left: $dialog-padding;
  padding: $title-link-padding;
  text-align: left;

  &::before {
    font-size: $title-link-arrow-size;
    content: '\1438';
  }
}

.form-generator__content {
  padding: spacing(1);
}

.form-generator__content--split {
  display: grid;
  grid-column-gap: spacing(2);
  grid-template-columns: repeat(auto-fill, calc(50% - #{$spacer-unit}));
  width: $form-content-split-width;
}

.form-generator__content--empty {
  padding: 0;
}

@media (max-width: $media-query-m) {
  .form-generator__content--split {
    display: block;
    width: auto;
  }
}

.form-generator__confirm-button {
  @include flex-center;

  flex-direction: column;
  width: 100%;
}

.skip-button {
  margin-top: spacing(2);
}

.form-generator__step-bar {
  @include text-title-small;

  margin: spacing(2) 0 0;
  color: $grey;
}
</style>

<style lang="scss">
$label-small-bottom-margin: 2px;

.form-generator__content-item {
  margin-bottom: spacing(1);

  // Remove the default asterisk and add (required)
  &.el-form-item.is-required:not(.is-no-asterisk) {
    > .el-form-item__label::before {
      margin-right: 0;
      content: '';
    }

    > .el-form-item__label::after {
      @include text-label;

      font-style: italic;
      content: ' (required)';
    }
  }

  .el-form-item__label {
    margin-bottom: $label-small-bottom-margin;
    padding: 0;
    color: $grey-dark;
    text-transform: capitalize;
  }

  .el-form-item__content {
    input::placeholder {
      text-transform: capitalize;
    }
  }

  &.form-generator__content-item--hidden {
    display: none;
  }
}
</style>
