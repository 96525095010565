import { HTTP } from '@/services/axios'

/**
 * @returns {Promise<object>}
 */
const fetchNotificationsSettings = async () => {
  const response = await HTTP.get('notifications/settings/')

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {string}          payload.type
 * @param   {string}          payload.platform
 * @param   {string}          payload.value
 *
 * @returns {Promise<object>}
 */
const updateNotificationsSettings = async ({ type, platform, value }) => {
  const response = await HTTP.patch('notifications/settings/update', {
    [type]: {
      [platform]: value
    }
  })

  return response.data
}

export default {
  fetchNotificationsSettings,
  updateNotificationsSettings
}
