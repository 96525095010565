/**
 * @param   {object}   state
 *
 * @returns {Function}
 */
const getNotificationsSettingsByType = state => type => state.data[type]

/**
 * @param   {object}  state
 *
 * @returns {boolean}
 */
const getIsNotificationsSettingsLoading = state => state.isLoading

/**
 * @param   {object}  state
 *
 * @returns {boolean}
 */
const getIsNotificationsSettingsUpdating = state => state.isUpdating

/**
 * @param   {object}  state
 *
 * @returns {boolean}
 */
const getHasNotificationsSettingsFailed = state => state.hasFailed

export default {
  getNotificationsSettingsByType,
  getIsNotificationsSettingsLoading,
  getIsNotificationsSettingsUpdating,
  getHasNotificationsSettingsFailed
}
