import { deepFreeze } from '@/services/utils'

export const ROLE_TYPE = deepFreeze({
  CREATOR: {
    name: 'creator',
    displayName: 'Creator',
    description: 'Can view and edit everything within this team.'
  },
  VIEWER: {
    name: 'viewer',
    displayName: 'Viewer',
    description: 'Can only view everything within this team.'
  }
})
