<template>
  <div class="detail-header">
    <div class="detail-header__color-run">
      <StitchColorRun :images="itemColorRun" />
    </div>
    <div class="detail-header__info">
      <div class="detail-header__title">
        <StitchTooltip :message="item.name">
          <h1>{{ item.name }}</h1>
        </StitchTooltip>
      </div>
      <table class="detail-header__table">
        <tr v-if="item.plmCode">
          <td>PLM Code</td>
          <td>{{ item.plmCode }}</td>
        </tr>
        <tr>
          <td>Info</td>
          <td>
            <div
              v-if="itemMeta.length > 0"
              class="detail-header__meta"
            >
              <ElementTag
                v-for="(value, index) in itemMeta"
                :key="index"
                size="mini"
                type="info"
              >
                {{ value }}
              </ElementTag>
            </div>
            <template v-else>
              No info available
            </template>
          </td>
        </tr>
        <tr>
          <td>Created on</td>
          <td>{{ createdAtFormatted }}</td>
        </tr>
        <tr v-if="libraryType === LIBRARY_TYPE.STYLE">
          <td>Comments</td>
          <td class="detail-header__comments">
            <StyleComments
              v-if="item"
              :item="item"
            />
          </td>
        </tr>
      </table>
      <div class="detail-header__actions">
        <StitchDropdown
          :dropdown-items="dropdownItems"
          :append-to-body="false"
          class="detail-header__dropdown"
          :title="`Edit ${libraryType} Information`"
        />
      </div>
    </div>
  </div>
</template>

<script>
// colorRun, meta
import { DataUtils } from '@/mixins/utils.js'
import { formatToLocaleDateLong } from '@/services/utils'
import StitchTooltip from '@/components/common/StitchTooltip'
import { IMAGE_RESOLUTION } from '@/constants/image'
import { STYLE_TYPE } from '@/constants/dropdownOptions'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { ItemShape } from '@/types'

export default {
  name: 'DetailHeader',

  components: {
    StitchTooltip
  },

  mixins: [DataUtils],

  props: {
    item: ItemShape.loose.isRequired
  },

  emits: ['edit-item', 'delete-item'],

  data () {
    return {
      LIBRARY_TYPE
    }
  },

  computed: {
    /**
     * @returns {Array}
     */
    dropdownItems () {
      return [
        {
          name: 'Edit Information',
          method: () => this.emitEditItem()
        },
        {
          name: `Delete ${this.libraryType}`,
          styles: [STYLE_TYPE.DANGER],
          method: () => this.emitDeleteItem()
        }
      ]
    },

    /**
     * @returns {object}
     */
    itemMeta () {
      return this.item.meta
    },

    /**
     * @returns {object}
     */
    itemColorRun () {
      return this.getColorRunResized(this.item, IMAGE_RESOLUTION.THUMBNAIL)
    },

    /**
     * @returns {string}
     */
    libraryType () {
      return this.item.libraryType
    },

    /**
     * @returns {string}
     */
    createdAtFormatted () {
      return formatToLocaleDateLong(this.item.createdAt)
    }
  },

  methods: {
    /**
     */
    emitEditItem () {
      this.$emit('edit-item', { styleId: this.item.id })
    },

    /**
     * @returns {Promise<MessageBoxData>}
     */
    emitDeleteItem () {
      return this.$confirm(
        `This will permanently delete ${this.item.name}. Continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.$emit('delete-item', {
            id: this.item.id,
            name: this.item.name
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
$meta-margin-left: spacing(1/2) * -1;
$meta-margin-bottom: -1 * spacing(2); // Hides the bottom padding from the last row of wrapped tags, making the whole table look cleaner.
$color-run-width: 40%;
$color-run-height: spacing(24);
$meta-list-name-width: 20%;
$meta-list-value-width: 80%;

.detail-header {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.detail-header__color-run {
  @include flex-center;

  width: $color-run-width;
  min-width: spacing(30);
  max-width: spacing(60);
  padding: spacing(2);

  > * {
    max-height: $color-run-height;
  }
}

.detail-header__info {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: spacing(2);
  overflow: hidden;
}

.detail-header__title {
  display: flex;
  align-items: flex-end;
  margin-bottom: spacing(2);

  /deep/ .el-tooltip {
    display: grid;
  }

  h1 {
    @include truncate-lines(1);

    margin-right: spacing(1);
    color: $grey-dark;
    line-height: 1.2;
  }
}

.detail-header__meta {
  margin-bottom: $meta-margin-bottom;
  margin-left: $meta-margin-left;

  > * {
    margin: 0 spacing(1/2) spacing(2);
  }
}

.detail-header__dropdown {
  display: inline-flex;
  margin-bottom: spacing(2);

  /deep/ .el-button {
    padding: 0;
  }
}

.detail-header__actions {
  display: flex;
  align-items: center;

  > * {
    margin-right: spacing(4);
  }
}

.detail-header__table {
  width: 100%;
  margin-bottom: spacing(1);

  tr {
    display: flex;
    margin-bottom: spacing(2);
  }

  td {
    padding: spacing(1) 0;
  }

  td + td {
    margin-left: spacing(1);
  }

  td:first-of-type {
    width: $meta-list-name-width;
    color: $grey;
  }

  td:last-of-type {
    width: $meta-list-value-width;
    color: $grey-light;
  }
}

.detail-header__comments {
  /deep/ .el-button {
    padding: 0;
  }
}
</style>
