import axios from 'axios'
import { HTTP, prefixWorkspaceUrls } from '@/services/axios'
import serverAPI from '@/services/serverAPI/index'

let previousCalledPage = -1
let controller = new AbortController()

/**
 * @param   {object} payload
 *
 * @returns {Array}
 */
const fetchItems = payload => {
  /**
   * Check if need to cancel previous calls.
   * Since all filter/sorting changes request the page=1, it's the safest way
   */
  if (payload.page === 1 || payload.page < previousCalledPage) {
    controller.abort()
    // Must create a new AbortController, otherwise the new requests will also be cancelled
    controller = new AbortController()
  }

  const { activeWorkspace, libraryType } = payload

  previousCalledPage = payload.page
  const url = prefixWorkspaceUrls({
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })
  const limit = payload.limit
  const offset = (payload.page - 1) * limit
  const sortingParameters = payload.sortingParameters

  const filters = {}
  for (const key in payload.filters) {
    if (payload.filters[key] && payload.filters[key].length !== 0) {
      filters[key] = getIdsAsString(payload.filters[key])
    }
  }

  // backend is expecting tag_id, although it is a list of tags named "tags"
  // check actionItems fetchFilteredItems()
  const { tags, ...remainingFilters } = filters

  const params = {
    params: {
      ...remainingFilters,
      tag_id: tags,
      search: payload.search,
      sort_by: sortingParameters.sortBy,
      order: sortingParameters.order,
      offset,
      limit
    },
    signal: controller.signal
  }

  return serverAPI.requestItemsURL(url, params)
}

/**
 * @param   {string}                      url
 * @param   {object}                      config
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const requestItemsURL = async (url, config) => {
  try {
    const response = await HTTP.get(url, config)

    return response.data
  } catch (error) {
    if (!axios.isCancel(error)) {
      console.warn(error)
    }

    return null
  }
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const fetchItemById = async payload => {
  const { libraryType, activeWorkspace } = payload
  const url = prefixWorkspaceUrls({
    url: `/${payload.id}`,
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })

  const response = await HTTP.get(url)

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const addItem = async payload => {
  const { libraryType, activeWorkspace } = payload

  if (payload.attributes) {
    const { plmCode, ...attributes } = payload.attributes
    payload.attributes = attributes
  }

  const url = prefixWorkspaceUrls({
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })
  const parameters = buildItemFormFields(payload)

  const response = await HTTP.post(url, parameters)

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const updateItem = async payload => {
  const { libraryType, activeWorkspace } = payload

  const url = prefixWorkspaceUrls({
    url: `/${payload.id}`,
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })

  if (payload.attributes) {
    const { plmCode, ...attributes } = payload.attributes
    payload.attributes = attributes
  }

  const parameters = buildItemFormFields(payload)
  const response = await HTTP.patch(url, parameters)

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const deleteItem = async payload => {
  const { libraryType, activeWorkspace } = payload
  const url = prefixWorkspaceUrls({
    url: `/${payload.id}`,
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })

  return await HTTP.delete(url)
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const assignItemToDivision = async payload => {
  const { libraryType, activeWorkspace } = payload
  const url = prefixWorkspaceUrls({
    url: `/${payload.id}/division/${payload.divisionId}/`,
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })

  const response = await HTTP.post(url)

  return response.data
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const unassignItemToDivision = async payload => {
  const { libraryType, activeWorkspace } = payload
  const url = prefixWorkspaceUrls({
    url: `/${payload.id}/division/${payload.divisionId}/`,
    libraryType,
    activeWorkspaceCode: activeWorkspace,
    checkIsLibrary: true
  })

  const response = await HTTP.delete(url)

  return response.data
}

/**
 * @param   {Array}  idArray
 *
 * @returns {string}
 */
const getIdsAsString = idArray => {
  if (idArray && idArray.length > 0) {
    const ids = idArray.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.toString() + ',',
      ''
    )

    return ids.slice(0, ids.length - 1)
  } else {
    return ''
  }
}

/**
 * @param   {object}          payload
 *
 * @returns {Promise<object>}
 */
const updateBlockVersion = async payload => {
  const fileForm = new FormData()

  if (payload.name) {
    // gives empty string because formData converts null into 'null'
    fileForm.append('name', payload.name || '')
  }

  if (payload.isMain === true || payload.isMain === false) {
    fileForm.append('is_main', payload.isMain)
  }

  const response = await HTTP.patch(
    `blocks/${payload.blockId}/versions/${payload.versionId}`,
    fileForm
  )

  return response.data
}

/**
 * @param   {object} payload
 *
 * @returns {object}
 */
const buildItemFormFields = payload => {
  const backendPayload = {}

  if (payload.name !== undefined) {
    backendPayload.name = payload.name
  }

  if (payload.plm_code !== undefined) {
    backendPayload.plm_code = payload.plm_code
  }

  if (payload.division_id !== undefined) {
    backendPayload.division_id = payload.division_id
  }

  // only for block
  if (payload.type !== undefined) {
    backendPayload.type = payload.type
  }

  // only for style & block
  if (payload.render_engine !== undefined) {
    backendPayload.render_engine = payload.render_engine
  }

  if (payload.product_group_id !== undefined) {
    backendPayload.product_group_id = payload.product_group_id
  }

  if (payload.trim_material_subtype_id !== undefined) {
    backendPayload.trim_material_subtype_id = payload.trim_material_subtype_id
  }

  if (payload.composition !== undefined) {
    backendPayload.composition = payload.composition
  }

  if (payload.fabric_material_subtype_id !== undefined) {
    backendPayload.fabric_material_subtype_id =
      payload.fabric_material_subtype_id
  }

  if (payload.sustainability_rating_id !== undefined) {
    backendPayload.sustainability_rating_id = payload.sustainability_rating_id
  }

  if (payload.visible !== undefined) {
    backendPayload.visible = payload.visible
  }

  // Style
  if (payload.season_id !== undefined) {
    backendPayload.season_id = payload.season_id
  }

  if (payload.group_id !== undefined) {
    backendPayload.group_id = payload.group_id
  }

  if (payload.price_id !== undefined) {
    backendPayload.price_id = payload.price_id
  }

  if (payload.attributes !== undefined) {
    backendPayload.attributes = payload.attributes
  }

  return backendPayload
}

/**
 * @param   {object}          payload
 * @param   {File}            payload.file
 * @param   {string}          payload.name
 * @param   {boolean}         payload.isMain
 * @param   {string}          payload.renderEngine
 * @param   {number}          payload.blockId
 *
 * @returns {Promise<object>}
 */
const addBlockVersion = async ({
  file,
  name,
  isMain,
  blockId,
  renderEngine
}) => {
  const fileForm = new FormData()

  if (file) {
    fileForm.append('model', file)
  }

  // gives empty string because formData converts null into 'null'
  fileForm.append('name', name || '')
  fileForm.append('is_main', isMain)
  fileForm.append('render_engine', renderEngine)

  const response = await HTTP.post(`/blocks/${blockId}/versions/`, fileForm)

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.blockId
 * @param   {number}          payload.versionId
 * @param   {number}          payload.imageId
 * @param   {File}            payload.file
 * @param   {boolean}         payload.isThumbnail
 *
 * @returns {Promise<object>}
 */
const updateBlockViewImage = async ({
  blockId,
  versionId,
  imageId,
  file,
  isThumbnail
}) => {
  const fileForm = new FormData()

  fileForm.append('is_thumbnail', isThumbnail || false)

  const response = await HTTP.patch(
    `blocks/${blockId}/versions/${versionId}/images/${imageId}`,
    fileForm
  )

  return response.data
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.blockId
 * @param   {number}          payload.versionId
 *
 * @returns {Promise<object>}
 */
const deleteVersion = async ({ blockId, versionId }) => {
  return await HTTP.delete(`blocks/${blockId}/versions/${versionId}`)
}

/**
 * @param   {object}          payload
 * @param   {number}          payload.blockId
 * @param   {number}          payload.versionId
 * @param   {number}          payload.imageId
 *
 * @returns {Promise<object>}
 */
const deleteBlockViewImage = async ({ blockId, versionId, imageId }) => {
  const response = await HTTP.delete(
    `blocks/${blockId}/versions/${versionId}/images/${imageId}`
  )

  return response.data
}

const itemAPI = {
  addItem,
  updateItem,
  deleteItem,
  fetchItemById,
  fetchItems,
  assignItemToDivision,
  unassignItemToDivision,
  requestItemsURL,
  updateBlockVersion,
  addBlockVersion,
  updateBlockViewImage,
  deleteVersion,
  deleteBlockViewImage
}

export default itemAPI
