import store from '@/store/store'
import styleUploadFormConfiguration from '@/stubs/styleUploadFormConfiguration/styleUploadFormConfiguration.json'
import { camelCase, cloneDeep } from 'lodash'

/**
 * Merges the default configuration with flexible attributes to be used by forms
 *
 * @returns {object} - Form Configuration
 */
export const getConfig = () => {
  const defaultConfiguration = cloneDeep(styleUploadFormConfiguration)

  const attributes = store ? store.getters.getStylesAttributes : []

  if (attributes) {
    Object.entries(attributes).forEach(entry => {
      const [key, value] = entry

      if (
        !defaultConfiguration.steps[1].fields.some(
          attribute => camelCase(attribute.id) === camelCase(key)
        )
      ) {
        defaultConfiguration.steps[1].fields.push({
          id: key,
          name: key,
          required: false,
          type: 'select',
          injectOptions: value
        })
      }
    })
  }

  return defaultConfiguration
}
