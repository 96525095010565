<template>
  <header class="the-header">
    <div class="the-header__workspace">
      <StitchDropdown
        v-if="canAccessWorkspaces"
        data-testid="workspace-switch"
        :icon-type="ICON_TYPE.MENU"
        :append-to-body="false"
        :dropdown-items="workspaceOptions"
      >
        <template #trigger>
          <img
            :src="activeWorkspace.thumbnail"
            :alt="`${activeWorkspace.name} Logo`"
            class="the-header__workspace-logo--active"
          >
        </template>
        <template #listItem="{ data }">
          <span
            class="the-header__workspace-item"
            data-testid="workspace-list-item"
          >
            <img
              v-if="data.thumbnail"
              :src="data.thumbnail"
              class="the-header__workspace-list-item-logo"
              :alt="`${data.name} Logo`"
            >
            {{ data.name }}
          </span>
        </template>
      </StitchDropdown>
    </div>
    <GroupsControl v-if="hasPermissions && !canAccessWorkspaces" />
    <router-link
      v-else
      :to="{ path: '/library/' }"
      class="the-header__logo"
    >
      <h2 class="the-header__logo-text">
        Stitch<span>HUB</span>
      </h2>
    </router-link>
    <ElementDropdown
      v-if="canManagePeople"
      class="the-header__right"
    >
      <ElementButton type="text">
        Settings
      </ElementButton>
      <ElementDropdownMenu
        slot="dropdown"
        :append-to-body="false"
      >
        <ElementDropdownItem
          ref="managePeople"
          @click.native="openUserAccessManager"
        >
          Manage People
        </ElementDropdownItem>
        <ElementDropdownItem
          v-if="canAccessNotificationsSettings"
          data-testid="notificationsSettingsButton"
          @click.native="openNotificationsSettings"
        >
          Notifications
        </ElementDropdownItem>
        <ElementDropdownItem
          class="the-header__logout"
          divided
          @click.native="logout(user, $hubApiBaseUrl, $rendersApiBaseUrl)"
        >
          Logout
        </ElementDropdownItem>
      </ElementDropdownMenu>
    </ElementDropdown>
    <ElementButton
      v-else
      class="the-header__right"
      type="text"
      @click="logout(user, $hubApiBaseUrl, $rendersApiBaseUrl)"
    >
      Logout
    </ElementButton>

    <StitchDialog
      v-if="canManagePeople"
      :show-close="false"
      :visible.sync="showUserAccessManager"
      append-to-body
      center
    >
      <HeaderAccessManager
        :can-add-invitation="canAddInvitation"
        :group="selectedGroup"
        :invitations="invitations"
        :new-invitee-list="newInviteeList"
        :roles="roles"
        :user="user"
        class="header-access-manager__content"
        @add-invitations="createInvitations"
        @update-invitation="updateInvitationRole"
        @change-filters="updateInvitationFilters"
        @remove-invitation="removeInvitation"
      />
      <div
        v-if="isLoadingUserAccessManager"
        class="header-access-manager__loader"
      >
        <StitchLoader />
      </div>
      <ElementButton
        slot="footer"
        class="the-header__dialog-button"
        plain
        type="primary"
        @click="showUserAccessManager = false"
      >
        Done
      </ElementButton>
    </StitchDialog>

    <HeaderNotificationsSettings :visible.sync="showNotificationsSettings" />
  </header>
</template>

<script>
// canUserAccessWorkspaces
import FeatureFlags from '@/services/featureFlags'
import { mapActions, mapGetters } from 'vuex'
import GroupsControl from '../GroupsControl'
import HeaderAccessManager from './components/HeaderAccessManager'
import HeaderNotificationsSettings from './components/HeaderNotificationsSettings'
import {
  applyPermissionsToInvitations,
  canPermitAction
} from '@/services/permissions'
import { ROLE_FLAG } from '@/constants/roleFlag'
import { ROLE_TYPE } from '@/constants/roleType'
import { DataUtils } from '@/mixins/utils'
import { FORM_NAME } from '@/constants/formName'
import { logout } from '@/services/utils'
import { ICON_TYPE } from '@/constants/dropdownOptions'

export default {
  name: 'TheHeader',

  components: {
    GroupsControl,
    HeaderAccessManager,
    HeaderNotificationsSettings
  },

  mixins: [DataUtils],

  data: () => ({
    ROLE_TYPE,
    FORM_NAME,
    ICON_TYPE,
    showWorkspaces: false,
    showUserAccessManager: false,
    isLoadingUserAccessManager: false,
    showNotificationsSettings: false,
    newInviteeList: [],
    invitationFilters: {
      groupId: null,
      roleId: null,
      search: ''
    }
  }),

  computed: {
    ...mapGetters(['getInvitations', 'getItemById', 'getActiveWorkspace']),

    ...mapGetters({
      roles: 'getRoles',
      user: 'getCognitoUserData',
      activePermission: 'getActivePermission',
      activeWorkspace: 'getActiveWorkspace'
    }),

    /**
     * @returns {object[]}
     */
    workspaceOptions () {
      const workspaceOptions = []

      workspaceOptions.push({
        name: 'Workspace & Members',
        method: () => {
          window.location.assign(`${process.env.VUE_APP_NEW_HUB_BASE_URL}admin`)
        }
      })

      Object.keys(this.user.workspaces).map(key => {
        if (this.user.workspaces[key].name !== this.activeWorkspace.name) {
          workspaceOptions.push(this.user.workspaces[key])

          return (workspaceOptions[workspaceOptions.length - 1].method = () => {
            return this.setActiveWorkspace(this.user.workspaces[key])
          })
        }

        return this.user.workspaces[key]
      })

      return workspaceOptions
    },

    /**
     * @returns {Array}
     */
    invitations () {
      return applyPermissionsToInvitations(
        this.activePermission,
        this.getInvitations
      )
    },

    /**
     * @returns {boolean}
     */
    canAddInvitation () {
      return this.hasPermissions && canPermitAction(ROLE_FLAG.CREATE_INVITE)
    },

    /**
     * @returns {boolean}
     */
    canManagePeople () {
      return this.hasPermissions && canPermitAction(ROLE_FLAG.VISIBLE_INVITE)
    },

    /**
     * @returns {boolean}
     */
    hasPermissions () {
      return Boolean(this.user.permissions && this.user.permissions.length > 0)
    },

    /**
     * @returns {number}
     */
    itemId () {
      return this.getParamId()
    },

    /**
     * @returns {object}
     */
    selectedGroup () {
      return this.activePermission && this.activePermission.group
    },

    /**
     * @returns {boolean}
     */
    canAccessNotificationsSettings () {
      return FeatureFlags.canUserAccessNotificationsSettings()
    },

    /**
     * @returns {boolean}
     */
    canAccessWorkspaces () {
      return FeatureFlags.canUserAccessWorkspaces()
    }
  },

  mounted () {
    if (this.activeWorkspace) {
      this.setActiveWorkspace(this.activeWorkspace)
    }
  },

  methods: {
    ...mapActions([
      'fetchInvitations',
      'fetchRoles',
      'addInvitations',
      'updateInvitation',
      'deleteInvitation',
      'setActiveWorkspace'
    ]),

    /**
     */
    openNotificationsSettings () {
      this.showNotificationsSettings = true
    },

    /**
     */
    async fetchInvitationsFiltered () {
      await this.fetchInvitations(this.invitationFilters)
    },

    /**
     * @param {object} filters
     */
    updateInvitationFilters (filters) {
      this.invitationFilters = filters

      this.fetchInvitationsFiltered()
    },

    /**
     * @param {object[]} invitations
     */
    async createInvitations (invitations) {
      try {
        await this.addInvitations({
          invitations
        })

        this.newInviteeList = []
      } catch (error) {
        const errorEmails = this.getEmailsFromError(error)

        invitations.forEach(invitation => {
          invitation.isValid = !errorEmails.includes(invitation.email)
        })

        this.newInviteeList = invitations
      }
    },

    /**
     * @param   {Error}    error
     *
     * @returns {string[]}
     */
    getEmailsFromError (error) {
      const errorResponse = error.response

      if (errorResponse.status === 400) {
        return Object.values(errorResponse.data).reduce(
          (errorEmails, errors) => {
            errors.forEach(error =>
              errorEmails.push(...Object.values(error.data))
            )

            return errorEmails
          },
          []
        )
      } else {
        return []
      }
    },

    /**
     * @param {object} payload
     */
    async updateInvitationRole (payload) {
      const { invitationId, roleId, refreshUser } = payload

      await this.updateInvitation({
        invitationId,
        roleId,
        refreshUser
      })
    },

    /**
     * @param {object} payload
     */
    async removeInvitation (payload) {
      await this.deleteInvitation({
        invitationId: payload.invitationId
      })
    },

    /**
     */
    async openUserAccessManager () {
      this.invitationFilters.groupId = this.activePermission.group.id

      this.isLoadingUserAccessManager = true
      this.showUserAccessManager = true

      await this.fetchRoles()
      await this.fetchInvitationsFiltered()

      this.isLoadingUserAccessManager = false
    },

    /**
     */
    logout: logout,

    /**
     * @param {boolean} success
     */
    formComplete (success) {
      if (success) {
        this.closeUpdateForm()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$header-access-manager-width: 100%;
$dialog-button-width: 200px;
$active-workspace-margin: 10px;
$logo-size: 32px;

.the-header {
  position: relative;
  z-index: $z-index-layer-1;
  display: flex;
  align-items: center;
  width: 100%;
  height: $main-header-height;
  padding: spacing(1) spacing(4);
  background-color: $white;
  box-shadow: $shadow-default;
}

.the-header__workspace {
  display: flex;
  align-items: center;
}

.the-header__workspace .the-header__workspace-logo--active {
  width: $logo-size;
  height: $logo-size;
  margin-left: $active-workspace-margin;
}

.the-header__workspace-item {
  display: flex;
  align-items: center;
}

.the-header__workspace .the-header__workspace-list-item-logo {
  width: $logo-size;
  height: $logo-size;
  margin-right: $active-workspace-margin;
}

.the-header__logo-text {
  @include position-absolute(center, center);

  line-height: 1;
  transition: color $transition;

  span {
    font-weight: $font-weight-bold;
  }
}

.the-header__logo:hover .the-header__logo-text {
  color: $blue;
}

.the-header__right {
  margin-left: auto;
}

.the-header__right .el-dropdown-menu__item {
  white-space: nowrap;
}

.header-access-manager__loader {
  @include position-absolute(center);

  width: 100%;
  height: 100%;
  background-color: $white-overlay;
  transition: opacity $transition-duration-2;
}

.header-access-manager__content {
  width: $header-access-manager-width;
}

.the-header__dialog-button {
  width: $dialog-button-width;
}

.the-header__logout {
  &,
  &:hover {
    color: $red;
  }
}
</style>
