import { HTTP } from '@/services/axios'

/**
 * @returns {object}
 */
const whoami = async () => {
  const response = await HTTP.get('/auth/whoami')

  return response.data
}

const userAPI = {
  whoami
}

export default userAPI
