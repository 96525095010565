import VueTypes from 'vue-types'
import { ContributorShape } from '@/types/user'
import { STYLE_EVENT_TYPE } from '@/constants/styleEventType'

const CommentShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  contributor: ContributorShape,
  style_id: VueTypes.number.isRequired,
  text: VueTypes.string
})

const StyleEventShape = VueTypes.shape({
  id: VueTypes.number.isRequired,
  comment: VueTypes.oneOfType([CommentShape, null]),
  type: VueTypes.oneOf(Object.values(STYLE_EVENT_TYPE)),
  style_id: VueTypes.number.isRequired,
  style_version_id: VueTypes.oneOfType([Number, null]),
  createdAt: VueTypes.oneOfType([String, null]),
  updatedAt: VueTypes.oneOfType([String, null]),
  isConverted: VueTypes.bool.def(true)
})
const StyleEventShapes = VueTypes.arrayOf(StyleEventShape)

export { CommentShape, StyleEventShape, StyleEventShapes }
