import { LIBRARY_TYPE } from '@/constants/libraryType'

/**
 * @param   {Store.state}                 state
 *
 * @returns {function({string}):object[]}       - Returns a curry function that
 *                                              receives a libraryType and returns an Items array
 */
const getItems =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].items
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({number}):object}       - Returns a curry function that
 *                                            receives an id and a libraryType and returns an Item
 */
const getItemById =
  state =>
    ({ id, libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].items.find(item => item.id === id)
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({string}):object}       - Returns a curry function that
 *                                            receives a libraryType and returns an Item's details
 */
const getItemDetail =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].itemDetail || null
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({string}):object}       - Returns a curry function that
 *                                            receives a libraryType and returns Sorted Parameters
 */
const getSortingItems =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].sorting
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({string}):object}       - Returns a curry function that
 *                                            receives a libraryType and returns pagination parameters
 */
const getPagingItems =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].paging
    }

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getItemUploadStatuses = state => {
  const libraryType = state.activeLibraryType

  return state[libraryType].uploadStatuses
}

/**
 * @param   {Store.state}                state
 *
 * @returns {function({string}):boolean}       - Returns a curry function that
 *                                             receives a libraryType and returns an whether it has reached the end
 */
const getHasItemsReachedEnd =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].hasReachedEnd
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({string}):object}       - Returns a curry function that
 *                                            receives a libraryType and returns the Applied Filters
 */
const getAppliedFilters =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].appliedFilters
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({string}):object}       - Returns a curry function that
 *                                            receives a libraryType and returns the available filters
 */
const getAvailableFilters =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].availableFilters
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function({string}):string}       - Returns a curry function that
 *                                            receives a libraryType and returns the searching query
 */
const getSearch =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].search
    }

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getActiveLibraryType = state => {
  return state.activeLibraryType
}

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getVersions = state => {
  if (!state[state.activeLibraryType].itemDetail) {
    return []
  }

  return state[state.activeLibraryType].itemDetail.versions
}

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getVersionById = state => id => {
  return state[state.activeLibraryType].itemDetail.versions.find(
    version => version.id === id
  )
}

/**
 * @param   {Store.state}   state
 * @param   {Store.getters} getters
 * @returns {object}
 */
const getMainVersionFromActiveStyle = (state, getters) => {
  return getters.getVersions.find(version => version.isMain)
}

/**
 * @param   {Store.state}   state
 * @param   {Store.getters} getters
 * @returns {object}
 */
const getMainVersionFromActiveBlock = (state, getters) => {
  return getters.getVersions.find(version => version.isMain)
}

/**
 *
 * @param   {Store.state}                           state
 * @param   {Store.getters}                         getters
 *
 * @returns {function({number}):string | undefined}         - Returns a curry function that
 *                                                          receives an id and returns an Image Url
 */
const getVersionThumbnail = (state, getters) => id => {
  const version = getters.getVersionById(id)
  const images = version.options.reduce(
    (imgs, option) => [...imgs, ...option.images],
    []
  )

  const image = images.find(image => image.is_thumbnail)

  return image && image.file_url
}

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getVersionsUploadInProgress = state => {
  const libraryType = state.activeLibraryType

  return state[libraryType].versionsUploadInProgress
}

/**
 * @param   {object} state
 *
 * @returns {object}
 */
const getAllVersionsImagesUploadInProgress = state => {
  const libraryType = LIBRARY_TYPE.STYLE

  return state[libraryType].allVersionsImagesUploadInProgress
}

/**
 * @param   {Store.state}                 state
 *
 * @returns {function({number}):object[]}       - Returns a curry function that
 *                                              receives an optionId and returns In Progress Uploads array
 */
const getOptionImagesUploadInProgress =
  state =>
    ({ optionId }) => {
      const libraryType = LIBRARY_TYPE.STYLE

      return state[libraryType].allOptionsImagesUploadInProgress[optionId] || []
    }

/**
 * @param   {Store.state}                state
 *
 * @returns {function({string}):boolean}       - Returns a curry function that
 *                                             receives a libraryType and returns whether it needs to scroll to top
 */
const getHasToScrollToTop =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].hasToScrollToTop
    }

/**
 * @param   {Store.state}               state
 *
 * @returns {function(number):object[]}       - Returns a curry function that
 *                                            receives an id and returns an Options array
 */
const getOptionsByVersionId = state => id => {
  const version = getVersionById(state)(id)

  if (!version) {
    return []
  }

  return version.options
}

/**
 * @param   {Store.state}               state
 *
 * @returns {function(number):object[]}       - Returns a curry function that
 *                                            receives an id and returns an Options array
 */
const getViewsByVersionId = state => id => {
  const version = getVersionById(state)(id)

  if (!version) {
    return []
  }

  return version.views
}

/**
 * @param   {Store.state}                       state
 *
 * @returns {function({number, number}):object}       - Returns a curry function that
 *                                                    receives a versionId and an optionId and returns an Option
 */
const getOptionById =
  state =>
    ({ versionId, optionId }) => {
      return getOptionsByVersionId(state)(versionId).find(
        option => option.id === optionId
      )
    }

/**
 * @param   {Store.state}                 state
 *
 * @returns {function({string}):object[]}       - Returns a curry function that
 *                                              receives a libraryType and returns an Items array
 */
const getPlmOptions =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].plmOptions
    }

/**
 * @param   {object}   state
 *
 * @returns {object[]}
 */
const getStyleEvents = state => {
  return state[LIBRARY_TYPE.STYLE].itemDetail.events.list
}

/**
 * @param   {object} state
 *
 * @returns {string}
 */
const getStyleEventsFilter = state => {
  return state[LIBRARY_TYPE.STYLE].itemDetail.events.filter
}

/**
 * @param   {Store.state}                 state
 *
 * @returns {function({string}):object[]}
 */
const getTags =
  state =>
    ({ libraryType } = {}) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].tags
    }

/**
 * @param   {Store.state}                         state
 *
 * @returns {function({string, string}):object[]}
 */
const getTagByText =
  state =>
    ({ text, libraryType }) => {
      libraryType = libraryType || state.activeLibraryType

      return state[libraryType].tags.find(tag => tag.text === text)
    }

/**
 * @param   {Store.state} state
 *
 * @returns {object[]}          - Attributes
 */
const getStylesAttributes = state => state[LIBRARY_TYPE.STYLE].attributes

const getters = {
  getItems,
  getItemById,
  getItemDetail,
  getSortingItems,
  getPagingItems,
  getItemUploadStatuses,
  getHasItemsReachedEnd,
  getAppliedFilters,
  getAvailableFilters,
  getSearch,
  getActiveLibraryType,
  getVersionsUploadInProgress,
  getAllVersionsImagesUploadInProgress,
  getOptionImagesUploadInProgress,
  getHasToScrollToTop,
  getVersions,
  getVersionById,
  getMainVersionFromActiveStyle,
  getMainVersionFromActiveBlock,
  getViewsByVersionId,
  getVersionThumbnail,
  getOptionsByVersionId,
  getOptionById,
  getPlmOptions,
  getStyleEvents,
  getStyleEventsFilter,
  getTags,
  getTagByText,
  getStylesAttributes
}

export default getters
